<div class="sidebar dark-mode" [ngClass]="{ 'sidebar-short': collapse }">
    <div class="profile-box" [ngClass]="darkMode ? 'card-dark-bar' : ' card-null '">
        <!-- <div class="text-center">
      <img
        src="{{
          user?.profile_picture
            ? 'http://cm.com/cmlocal/public/app/images/' + user?.profile_picture
            : 'assets/img/nav/user.png'
        }}"
        class="img-circle profile-img"
      />
      <h3 class="title no-margin" [ngClass]="darkMode ? 'card-dark-modal-heding' : ' card-null '">
        <span>{{ user?.first_name }} {{ user?.last_name }}</span>
      </h3>
      <p class="sub-title no-margin" [ngClass]="darkMode ? 'card-dark-modal-heding' : ' card-null '">
        <strong>{{ user?.role ? user.role : "Unknown" }}</strong>
      </p>
    </div>  -->
        <div class="text-center">
            <img src="{{user?.profile_picture ? 'https://cmtest.pointclickintegrate.com/public/app/images/' + user?.profile_picture : 'assets/img/nav/logo.png'}}"
                class="img-circle profile-img" id="brandLogo" />
        </div>
    </div>

    <ul class="nav bg-blue" [ngClass]="darkMode ? 'card-dark-navG' : ' card-null '">
        <li class="forDark">
            <a href="" class="dash" [routerLink]="['/app/dashboard']" routerLinkActive="active">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/dashboard.png" />
                <span>{{ "sidebar.dashboard" | translate }}</span>
            </a>
        </li>
        <li class="forDark">
            <a *ngIf="user.role != 'SuperAdmin' &&  Inventory == 'false'" style="cursor: no-drop;" (click)="showerror()"
                class="flex_nav">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg" />
                <span>{{ "sidebar.inventory" | translate }}</span>
            </a>
            <a *ngIf="user.role == 'SuperAdmin'" routerLink="/app/inventory" routerLinkActive="active" class="flex_nav">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg" />
                <span>{{ "sidebar.inventory" | translate }}</span>
            </a>

            <a *ngIf="user.role != 'SuperAdmin' && Inventory == 'true' " class="flex_nav" routerLink="/app/inventory"
                routerLinkActive="active">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg" />
                <span>{{ "sidebar.inventory" | translate }}</span>
            </a>
        </li>

        <li class="forDark">
            <a *ngIf="user.role != 'SuperAdmin' && Arrivals == 'false'" style="cursor: no-drop;" (click)="showerror()"
                class="flex_nav">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg" />
                <span>{{ "sidebar.arrivals" | translate }}</span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin' && Arrivals == 'true'" routerLink="/app/arrivals"
                routerLinkActive="active" class="flex_nav">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg" />
                <span>{{ "sidebar.arrivals" | translate }}</span>
            </a>
            <a *ngIf="user.role == 'SuperAdmin' " routerLink="/app/arrivals" routerLinkActive="active" class="flex_nav">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg" />
                <span>{{ "sidebar.arrivals" | translate }}</span>
            </a>
        </li>


        <li class="forDark">
            <a *ngIf="user.role != 'SuperAdmin' && Rates_Packages == 'true'" routerLink="/app/rates_packages"
                routerLinkActive="active" class="flex_nav">
                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>{{ "sidebar.rates_packages" | translate }}</span>
            </a>
            <a *ngIf="user.role == 'SuperAdmin' " routerLink="/app/rates_packages" routerLinkActive="active"
                class="flex_nav">
                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>{{ "sidebar.rates_packages" | translate }}</span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin'  && Rates_Packages == 'false'" style="cursor: no-drop;"
                (click)="showerror()" class="flex_nav">
                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>{{ "sidebar.rates_packages" | translate }}</span>
            </a>
        </li>


        <li class="forDark">
            <a *ngIf="user.role == 'SuperAdmin'  || Rates_Packages == 'true' " href="" class="otaz"
                [routerLink]="['/app/manage_otas']" routerLinkActive="active">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/ots-channels.svg" />
                <span>{{ "sidebar.otas_channels" | translate }}</span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin'  && Rates_Packages == 'false' " style="cursor: no-drop;" class="otaz"
                (click)="showerror()">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/ots-channels.svg" />
                <span>{{ "sidebar.otas_channels" | translate }}</span>
            </a>
        </li>


        <li class="forDark">
            <a href="" *ngIf="user.role == 'SuperAdmin'  || Reports == 'true' " class="rep"
                [routerLink]="['/app/reports_analytics']" routerLinkActive="active">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/reports.svg" />
                <span>{{ "sidebar.reports" | translate }}</span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin'  && Reports == 'false' " style="cursor: no-drop;" class="otaz"
                (click)="showerror()">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/reports.svg" />
                <span>{{ "sidebar.reports" | translate }}</span>
            </a>
        </li>


        <li class="forDark">
            <a href="https://pms.cgslhcm.com/" class="pmss" routerLinkActive="active">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/pms.svg" />
                <span data-toggle="tooltip" title="{{ 'sidebar.pms' | translate }}">{{"sidebar.pms" | translate}}</span>
            </a>
        </li>


        <li class="forDark">
            <a href="" *ngIf="user.role == 'SuperAdmin'  || Back_Office == 'true'" class="back_off"
                [routerLink]="['/app/back_office']" routerLinkActive="active"
                [ngClass]="{'active': tasksActive.isActive || settingsActive.isActive }">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg" />
                <span>{{ "sidebar.back_office" | translate }}</span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin'  && Back_Office == 'false' " style="cursor: no-drop;" class="otaz"
                (click)="showerror()">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg" />
                <span>{{ "sidebar.back_office" | translate }}</span>
            </a>


            <a routerLink="/main" routerLinkActive #tasksActive="routerLinkActive" style="display: none"></a>
            <a routerLink="/app/back_office" routerLinkActive #settingsActive="routerLinkActive"
                style="display: none"></a>
        </li>

        <li class="forDark">
            <a href="" (click)="logout()">
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/logout.svg"
                    style="width: 48px; height: 46px;" />
                <span style="padding-left:10px;">{{ "sidebar.logout" | translate }}</span>
            </a>
        </li>
        <li class="bg-white" [ngClass]="darkMode ? 'card-dark-bar' : ' card-null '">
            <a (click)="do_collapse()" class="text-right">
                <img *ngIf="!collapse" src="assets/img/nav/arrow_left.png" class="collapse-arrow" />
                <img *ngIf="collapse" src="assets/img/nav/arrow_right.png" class="collapse-arrow" />
            </a>
        </li>
    </ul>
</div>